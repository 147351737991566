import React, { useState } from 'react';
import { Row, Col, Grid } from 'react-flexbox-grid';
import { useAlert } from 'react-alert'
import axios from 'axios';

function UnsubscribeForm() {
  const [email, setEmail] = useState('');
  const alert = useAlert();

  const handleChange = (event) => {
    setEmail(event.target.value)
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!(isEmail(email))) {
      alert.error(`Please enter a valid email address`);
      return
    }
    unsubscribeEmail(email).then(res => {
      if (res.status >= 200 && res.status < 300) {
        alert.success(<div className="notification-text">Successfully Unsubscribed <span className="truncate">{email}</span> <span className="redirect">Now redirecting...</span></div>, {
          onClose: () => {
            window.location.href = "https://www.beenverified.com";
          }
        });
      } else {
        alert.error(`Backend server returned status code ${res.status} ${res.statusText} `);
      }
    })
      .catch(error => {
        alert.error(`${error}`);
      });
  };

  const isEmail = (email) => {
    // https://www.4codev.com/react/the-example-for-check-valid-email-format-in-react-idpx1567194306355418166.html
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email ? re.test(String(email).toLowerCase()) : false;
  };

  const unsubscribeEmail = async (email) => {
    return await axios.post(
      process.env.REACT_APP_UNSUBSCRIBE_ENDPOINT_URL,
      {
        email: `${email}`,
        vendor: `bv-email.com`
      }
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid>
        <Row className="form" center="xs">
          <Col id="form-input" xs={12} sm={12} md={10} lg={10}>
            <input className="input block" type="text" name="email" placeholder="Email" onChange={handleChange} value={email} />
          </Col>
          <Col id="form-button" xs={12} sm={12} md={2} lg={2}>
            <button className="btn block" type="submit">Submit</button>
          </Col>
        </Row>
      </Grid>
    </form>
  );
}

export default UnsubscribeForm;
